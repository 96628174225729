<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style scoped>
  .w20 {
    width: 20%;
  }

  .w20:last-child {
    width: 19%;
  }

  .w33 {
    width: 33%;
  }

  .w50 {
    width: 50%;
  }

  .w66 {
    width: 66%;
  }

  .w-99 {
    width: 99%;
  }

  .w100 {
    width: 100%;
  }

  .guding-top {
    width: 66%;
    min-height: 200px;
  }

  .top-height {
    max-height: 200px;
    line-height: 200px
  }

  .less-width .w-50 {
    width: 49.5% !important;
  }

  .dragArea {
    padding: 30px 0;
    display: flex;
  }

  .msgContent {
    border: unset;
  }


  .msgName {
    width: 120px;
  }

  .grey-time {
    width: 36%;
  }

  .heighLabel {
    height: 132px;
    line-height: 132px;
  }

  .msgLabel {
    border-right: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
    height: 40px;
    line-height: 40px;
  }

  .border-diff .msgLabel {
    border-right: unset;
  }

  .msgContent.border-diff .msgLabel:nth-child(n+5) {
    border-bottom: unset;

  }

  .less-width .msgLabel {
    border-bottom: unset;
  }

  .d-border-bottom {
    border-bottom: 1px solid #DFDFDF;
  }

  .msgContent .msgLabel {
    border-top: unset;
  }

  .msgVal {
    padding: 0 10px;
  }

  .msgContent .msgVal {
    border-top: unset;
  }

  .list-group-item {
    background-color: rgb(255, 252, 239);
    cursor: pointer;
  }

  /deep/ .el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210)
  }

  .guding-col {
    flex-wrap: wrap;
    border-bottom: 1px solid #DFDFDF;
    max-height: 160px;
    box-sizing: border-box;
  }

  .guding-col .msgLabel:nth-child(n-1) {
    border-bottom: unset;
  }

  .border-top {
    border-top: 1px solid #DFDFDF !important;
  }

  .border-right {
    border-right: 1px solid #DFDFDF !important;
  }

  .guding-img {
    width: 33%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DFDFDF;
  }

  @media screen and (min-width: 1200px) {
    .msgName {
      min-width: 120px;
      width: 120px;
    }
  }


  .auto-scroll {
    overflow-y: auto;
  }

  /deep/.el-radio-group label {
    margin-bottom: 0;
  }

  .notice {
    height: 50px;
    overflow-y: auto;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import draggable from "vuedraggable";
  import YzSelect from "@/components/form/yzSelect.vue";
  import YzSelectMulit from "@/components/form/yzSelectMulit.vue";
  import {
    getDicts
  } from "@/api/common/dict.js";
  import {
    getExamDetails,
  } from "@/api/admin/exam/examRecord.js"
  import {
    covertToForm
  } from "@/api/common/draggable.js";
  import {
    getTemplateList,
    getTemplateListByKsbmh,
    importTemplate
  } from "@/api/admin/exam/examTemplate.js"
  import $ from "jquery"
  // import {
  //   createKsbmb
  // } from "@/api/admin/exam/createExamku.js"
  export default {
    name: "clone-on-control",
    display: "Clone on Control",
    instruction: "Press Ctrl to clone element from list 1",
    order: 4,
    components: {
      draggable,
      YzSelect,
      YzSelectMulit,
      Layout,
      PageHeader,
    },
    data() {
      return {
        title: "考试报名信息管理 ",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "模板预览",
            active: true
          }
        ],
        sid: "",
        form: {},
        chooseId: {},
        model: [],
        moudleList: [],
        mid: "",
        mbmc: "",
        mouleName: "",
        list1: [],
        list2: [],
        list3: [],
        gdList: [],
        gudingList: [{
            name: "姓名",
            val: "xm"
          },
          {
            name: "性别",
            val: "xbm"
          },
          {
            name: "证件类型",
            val: "sfzjlxm"
          },
          {
            name: "证件号码",
            val: "sfzjh"
          },
          {
            name: "出生年月",
            val: "csrq"
          },
          {
            name: "民族",
            val: "mzm"
          },
        ],
        ksbmbh: "",
        ksbmb: "",
        isSaveMold: false,
        listChoose: [],
        controlOnStart: true,
        showComp: false,
        examForm: {},
        addForm: {
          name: "",
          icon: "",
          must: true,
          type: "select",
          width: "w50",
          bound: ["hyztm", "hyztmc"],
          dict: "dict_hyzt",
        },
        adddw: [{
          name: "报考单位",
          val: "bkdwzym",
          zdbl: 50,

        },
          {
            name: "报考职位",
            val: "bkgwxkm",
            zdbl: 50,
          },
        ],
        addxk: [{
          name: "报考专业",
          val: "bkdwzym",
          zdbl: 100,

        }],
      };
    },
    computed: {
      mainHeight() {
        let height = this.$refs.main.innerHeight;
        return height
      }
    },
    methods: {
      chooseEle(e) {
        this.chooseId = e;
        this.$forceUpdate()
      },
      wChange() {
        this.$forceUpdate();
      },
      // 提交创建好的模板

      clone(e) {
        this.chooseId = JSON.parse(JSON.stringify(e))
        return this.chooseId;
      },
      pullFunction() {
        return this.controlOnStart ? "clone" : true;
      },
      start({
        originalEvent
      }) {
        this.controlOnStart = originalEvent.ctrlKey;
      },
      addEnd(e) {
        console.log(this.$refs["list1Container"].$el.clientHeight)
        if (this.$refs["list1Container"].$el.clientHeight > 210) {
          var a = this.list2[e.newIndex];
          this.list2.splice(e.newIndex, 1);
          this.list1.push(a);
        }
      },
      addComp() {
        this.showComp = true;
        this.addForm = {
          name: "",
          icon: "",
          must: false,
          type: "select",
          width: "w50",
          bound: "",
          dict: "",
          length: 0,
        };
      },
      // 加载字典数据
      loadDict(list) {
        let idx = [];
        let dicts = [];
        for (let i in list) {
          let item = list[i];
          if (item.dict) {
            if (item.glzdb || item.glzdb == null) {
              idx.push(i);
              dicts.push(item.dict);
            } else {
              item.data = item.zdkxz.split("|").map(item => {
                return {
                  name: item,
                  value: item
                }
              })
            }
          }
        }
        if (dicts.length != 0) {
          getDicts(...dicts).then((res) => {
            for (let i in dicts) {
              let id = idx[i];
              let dictName = dicts[i];
              list[id]["data"] = res[dictName];
              this.$forceUpdate()
            }
          });
        }
      },
      loadDicts(...lists) {
        lists.forEach(item => {
          this.loadDict(item)
        })
      },
      // 提交创建好的模板
      getTemplateList(type) {
        this.gdList = JSON.parse(JSON.stringify(this.gudingList))
        getTemplateList().then(res => {
          if (res.status) {
            this.list1 = []
            this.list2 = []
            this.list3 = []
            var data = res.data;
            let func;
            let param;
            if (type == 1) {
              func = getTemplateListByKsbmh
              param = this.ksbmbh
            }
            if (type == 2) {
              func = importTemplate
              param = this.mid
            }
            func(param).then(res1 => {
              if (res1.status) {
                data = data.filter(item => {
                  let flag = true;
                  for (let i in res1.data) {
                    if (res1.data[i].zddm == item.zddm) {
                      res1.data[i].sfxtzd = item.sfxtzd
                      flag = false;
                    }
                  }
                  return flag;
                })
                covertToForm(res1.data).forEach(item => {
                  if (this.examForm.zpysyq != 4) {
                    if (item.listId == 1) {
                      this.list2.push(item)
                    } else {
                      this.list3.push(item)
                    }
                  } else {
                    this.list3.push(item)
                  }

                })
                this.list1 = covertToForm(data)
                if (this.examForm.kmxzfs == "1") {
                  this.gdList.unshift(...this.addxk)
                } else {
                  this.gdList.unshift(...this.adddw)
                }
                if (this.examForm.kslxmc == "其他申请报名") {
                  this.list1.unshift({
                    sfbt: true,
                    zddm: "xm",
                    zdmc: "姓名",
                    data: "",
                    zdfl: "1",
                    txlx: "input",
                    zdbl: "50",
                    readOnly: true,
                    json: "xm",
                    sfxtzd: 1,
                    zdcd: 50
                  })
                }
                this.gdList.forEach(k => {
                  this.list1.forEach((v, index) => {
                    if (JSON.stringify(v.bound) == JSON.stringify(k.val)) {
                      this.list1.splice(index, 1)
                    }
                  })
                })
                this.loadDicts(this.list1, this.list2, this.list3);
              }
            })
          }

        })
      },

      getDetail(ksbmbh) {
        getExamDetails(ksbmbh).then(res => {
          if (res.status) {
            this.examForm = res.data
            console.log(this.examForm.zpysyq)
            if (this.examForm.zpysyq == 4) {
              $(".guding-img").css("display", "none")
              $(".guding-top").css("width", "99%")
              $(".guding-top").addClass("border-right")
            }
            this.getTemplateList(2)

          }
        })
      }
    },

    mounted() {
      this.sid = this.$route.query.sid
      this.ksbmbh = this.$route.query.ksbmbh
      if (this.ksbmbh) {
        this.getDetail(this.ksbmbh);
      }else{
        this.getTemplateList(2)
      }
    },
    created() {
      this.mid = this.$route.query.mid
      this.mbmc = this.$route.query.mbmc
    },

  };
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card" style="min-height: 470px;">
      <div class="card-body">
        <div class="outerCotent mt-3 ">
          <div class="style-set-box">
            <div class="style-top flexList w-100">
              <div class="col-lg-12 p-0 ">表单样式预览</div>
            </div>
            <el-row>
              <el-col :span="24" class="style-right" ref='main' id="mians" style="min-height: 600px;">
                <div class="style-title w-99 font-size-16">{{mbmc}}
                </div>
                <div class=" w-99"><textarea rows="2" cols="" class="notice w-100 form-control"
                    v-model="examForm.kstsxx" disabled placeholder="请填写报名注意事项"></textarea>
                </div>
                <div class="mt-3 exam-form">
                  <el-row style="" class="border-diff top-height">
                    <el-col ref="list1Container" class="guding-top">
                      <div class="flexList w-100  guding-col">
                        <div class="w-100">
                          <div class="flexList w-100 msgLabel " v-if="examForm.kmxzfs==2">
                            <div class="msgName">报考单位
                            </div>
                            <div class="msgVal selectShow flexList border-top">
                            </div>
                          </div>
                          <div class="flexList w-100 msgLabel " v-if="examForm.kmxzfs==1">
                            <div class="msgName">报考专业</div>
                            <div class="msgVal selectShow flexList border-top">
                            </div>
                          </div>
                        </div>

                        <div class="flexList w-50 msgLabel" v-for="(item,index) in gdList" :key="index">
                          <div class="msgName">{{item.name}}
                          </div>
                          <div class="msgVal">
                          </div>
                        </div>
                      </div>

                      <draggable class="msgContent   border-diff flexList fs-xs " :disabled="true" :list="list2" group="people"
                        style="min-height: 40px" @add="addEnd" >
                        <div @click="chooseEle(element)" class="flexList msgLabel canmove"
                          :class="['w' + element.width,element.type == 'textarea'?'msgtextarea':'']"
                          v-for="element in list2" :key="element.id">
                          <div class="msgName">
                            <span style="color: red" v-if="element.must">*</span>{{ element.name }}
                          </div>
                          <div class="msgVal selectShow flexList">
                            <yz-select-mulit v-model="form" :dict="element.data" :bounds="[
                                                                                      ['ssdm', 'ssmc'],
                                                                                      ['djsdm', 'djsmc'],
                                                                                      ['qxdm', 'qxmc'],
                                                                                    ]" v-if="element.type == 'local'">
                            </yz-select-mulit>
                            <input v-model="form[element.bound]" :placeholder="element.name"
                              v-if="element.type == 'input'" class="form-control w-100 h30" />
                            <input type="date" v-model="form[element.bound]" :placeholder="element.name"
                              v-if="element.type == 'date'" class="form-control w-100 h30" />
                            <textarea v-model="form[element.bound]" rows="3" :placeholder="element.name"
                              v-if="element.type == 'textarea'" class="form-control w-100 " /></textarea>
                            <yz-select :dict="element.data" class="w-100" :bound="element.bound" v-model="form"
                              v-if="element.type == 'select'"></yz-select>
                            <yz-select-mulit v-model="form" :dict="element.data" :bounds="element.bound"
                              v-if="element.type == 'selectEx'"></yz-select-mulit>
                            <b-form-checkbox v-if="element.type == 'switch'" style="line-height: 25px;"
                              v-model="form[element.bound]" switch class="switch-check">
                            </b-form-checkbox>
                            <el-radio-group v-if="element.type == 'radio'" v-model="form[element.bound]">
                              <el-radio :label="item.value" v-for="item in element.data" :key="item.value">
                                {{ item.name }}
                              </el-radio>
                            </el-radio-group>
                          </div>
                        </div>
                      </draggable>
                    </el-col>
                    <el-col class="guding-img">
                      <img :src="require('@/assets/images/person/person-none.png')" alt=""
                        style="width: 120px; height: 160px" />
                    </el-col>
                  </el-row>
                  <el-row>
                    <draggable style=" min-height:40px;width: 99%; flex-wrap: wrap" class="flexList fs-xs less-width"
                      :list="list3" @add="addEnd" group="people" :disabled="true" :class="[list3.length?'d-border-bottom':'']" >
                      <div @click="chooseEle(element)" class="flexList msgLabel canmove"
                        :class="['w' + element.width,element.type == 'textarea'?'msgtextarea':'']"
                        v-for="element in list3" :key="element.id">
                        <div class="msgName">
                          <span style="color: red" v-if="element.must">*</span>{{ element.name }}
                        </div>
                        <div class="msgVal selectShow flexList">
                          <yz-select-mulit v-model="form" :dict="element.data" :bounds="[
                                                                          ['ssdm', 'ssmc'],
                                                                          ['djsdm', 'djsmc'],
                                                                          ['qxdm', 'qxmc'],
                                                                        ]" v-if="element.type == 'local'">
                          </yz-select-mulit>
                          <input v-model="form[element.bound]" :placeholder="element.name"
                            v-if="element.type == 'input'" class="form-control w-100 h30" />
                          <input type="date" v-model="form[element.bound]" :placeholder="element.name"
                            v-if="element.type == 'date'" class="form-control w-100 h30" />
                          <textarea rows="3" v-model="form[element.bound]" :placeholder="element.name"
                            v-if="element.type == 'textarea'" class="form-control w-100 "></textarea>
                          <yz-select :dict="element.data" style="width: 100%;" :bound="element.bound" v-model="form"
                            v-if="element.type == 'select'"></yz-select>
                          <yz-select-mulit v-model="form" :dict="element.data" :bounds="element.bound"
                            v-if="element.type == 'selectEx'"></yz-select-mulit>
                          <b-form-checkbox v-if="element.type == 'switch'" style="line-height: 25px;"
                            v-model="form[element.bound]" switch class="switch-check">
                          </b-form-checkbox>
                          <el-radio-group v-if="element.type == 'radio'" v-model="form[element.bound]">
                            <el-radio :label="item.value" v-for="item in element.data" :key="item.value">{{ item.name }}
                            </el-radio>
                          </el-radio-group>
                        </div>
                      </div>

                    </draggable>
                    <div class="w-99">
                      <textarea rows="2" cols="" class="notice w-100 form-control" v-model="examForm.ksbzxx"
                        placeholder="请填写考试备注信息   " disabled></textarea>
                    </div>

                  </el-row>

                </div>
              </el-col>

            </el-row>

          </div>

        </div>
      </div>
    </div>

    <!-- 弹窗开始 -->
    <!--添加机构用户-->


    <!-- 弹窗结束-->
  </Layout>
</template>
